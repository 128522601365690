<template>
  <div v-if="(getRight('subscription') && permittedSubscriptions.length) || getRight('superadmin')" class="card">
    <div class="card-header card-header-divider header_green">
      Дневник Эмоций &ndash; статистика записей
    </div>
    <div class="card-body">
      <div class="analytics_controls">
        <div class="analytics_form">
          <div class="select_wrapper">
            <custom-select-2
                :label="'Период'"
                name="period"
                :options="periods"
                v-model="periodForm"
            />
          </div>
          <div class="button_wrapper">
            <button @click="getAnalyticsData" class="btn btn-accent">Получить</button>
          </div>
        </div>
        <div v-if="periodForm === 'custom'" class="custom_form">
          <custom-input :type="'date'" :label="'С'" name="start_period" :maxValue="todayDate"
                        v-model="customPeriodForm.start_date"/>
          <custom-input :type="'date'" :label="'По'" name="end_period" :minValue="customPeriodForm.start_date ?? ''"
                        :maxValue="todayDate" v-model="customPeriodForm.end_date"/>
        </div>
      </div>
      <div>
        <div class="preloader" v-if="!isLoaded">
          <div>
            <mini-preloader/>
          </div>
        </div>
        <div v-if="isLoaded && analyticsData">
          <div v-if="!analyticsData.emotions || !analyticsData.emotions.length" class="no_results">
            Нет данных
          </div>
          <div v-else class="chart_wrapper">
            <div class="chart emotions_chart">
              <bar-chart
                  :chartData="emotionData"
                  :options="emotionBarOptions"
                  :height="400"
              />
            </div>
            <div class="chart triggers_chat">
              <bar-chart
                  :chartData="triggerData"
                  :options="triggerBarOptions"
                  :height="400"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="subscription-required">
    Чтобы использовать этот инструмент, необходимо оформить подписку. Пожалуйста, сделайте это для получения доступа.
  </div>
</template>

<script>
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import {onBeforeMount, reactive, ref, watch} from "@vue/runtime-core";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import {useStore} from "vuex";
import {computed} from "@vue/reactivity";
import {notify} from "@kyvg/vue3-notification";
import {BarChart} from "vue-chart-3";
import MiniPreloader from "@/components/Technical/MiniPreloader.vue";
import useCheckRights from "@/mixins/useCheckRights";

export default {
  name: "DiaryAnalytics",
  components: {MiniPreloader, CustomInput, CustomSelect2, BarChart},
  setup() {
    const store = useStore();
    const {getRight} = useCheckRights();
    const user = computed(() => store.state.profile.profile);
    const analyticsData = computed(() => store.state.diary.diaryAnalytics);
    const periodForm = ref('week');
    const customPeriodForm = reactive({
      start_date: null,
      end_date: null
    });
    const isLoaded = ref(true);
    const today = new Date();
    const currentDate = today.getDate();
    const currentMonth = today.getMonth() + 1;
    const todayDate = today.getFullYear() + '-' + (currentMonth < 10 ? '0' + currentMonth : currentMonth) + '-' + (currentDate < 10 ? '0' + currentDate : currentDate);
    const periodString = ref('');

    const permittedSubscriptions = computed(() => store.state.subscription.permittedSubscriptions)

    const emotionColors = {
      'Интерес': '#f3ed97',
      'Ожидание': '#efb773',
      'Настороженность': '#fa6f1e',
      'Безмятежность': '#eeefb3',
      'Радость': '#eaf17c',
      'Восторг': '#f2ec34',
      'Принятие': '#cdddb5',
      'Доверие': '#9dcb87',
      'Восхищение': '#61b959',
      'Тревога': '#a1cd81',
      'Страх': '#6db36a',
      'Ужас': '#2f9358',
      'Растерянность': '#82cfda',
      'Удивление': '#54b9cc',
      'Изумление': '#32acba',
      'Грусть': '#92b3d3',
      'Печаль': '#5486bf',
      'Горе': '#3475ac',
      'Скука': '#bbabd7',
      'Неудовольствие': '#8977b9',
      'Отвращение': '#64549f',
      'Досада': '#f0b4be',
      'Злость': '#ea79a8',
      'Гнев': '#e74b96',
      'Смущение': '#c5bab1',
      'Стыд': '#a08d74',
      'Вина': '#7e684e',
    };

    const emotionData = computed(() => {
      if (analyticsData.value && analyticsData.value.emotions) {
        let labels = [];
        let intensity = [];
        let backgroundColors = [];

        const sortedEmotions = [...analyticsData.value.emotions].sort((a, b) => {
          return b.value.length - a.value.length;
        });

        const groupedEmotions = [];
        let currentGroup = [];

        sortedEmotions.forEach((emotion, index) => {
          if (index === 0 || emotion.value === sortedEmotions[index - 1].value) {
            currentGroup.push(emotion);
          } else {
            groupedEmotions.push(currentGroup);
            currentGroup = [emotion];
          }
        });

        if (currentGroup.length > 0) {
          groupedEmotions.push(currentGroup);
        }

        groupedEmotions.forEach((group, groupIndex) => {
          group.forEach((emotion) => {
            labels.push(emotion.value);
            intensity.push(emotion.intensity);
            backgroundColors.push(emotionColors[emotion.value]);
          });

          if (groupIndex < groupedEmotions.length - 1) {
            labels.push('');
            intensity.push(null);
            backgroundColors.push('transparent');
          }
        });

        return {
          labels: labels,
          datasets: [
            {
              label: 'Интенсивность',
              data: intensity,
              backgroundColor: backgroundColors,
            },
          ],
        };
      }
    });

    const triggerData = computed(() => {
      if (analyticsData.value && analyticsData.value.triggers) {
        const sortedTriggers = [...analyticsData.value.triggers].sort((a, b) => {
          return b.value.length - a.value.length;
        });

        let labels = sortedTriggers.map((el) => el.value);
        let emotions = sortedTriggers.map((el) => el.count);

        return {
          labels: labels,
          datasets: [
            {
              label: 'Количество',
              data: emotions,
              backgroundColor: '#2ac285',
            },
          ],
        };
      }
    });

    const emotionBarOptions = {
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            autoSkip: false,
            callback: function (value, index) {
              const labels = this.chart.data.labels;
              const totalLabels = labels.length;

              if (labels[index] && labels[index] !== '') {
                let groupStart = index;
                while (groupStart > 0 && labels[groupStart - 1] === labels[index]) {
                  groupStart--;
                }
                let groupEnd = index;
                while (groupEnd < totalLabels - 1 && labels[groupEnd + 1] === labels[index]) {
                  groupEnd++;
                }

                const middleIndex = Math.floor((groupStart + groupEnd) / 2);
                if (index === middleIndex) {
                  return labels[index];
                }
              }

              if (index === totalLabels - 1 && labels[index]) {
                return labels[index];
              }

              return '';
            }
          },
          title: {
            display: true,
            text: 'Эмоция',
            font: {
              size: 14,
              weight: 600,
            },
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
          title: {
            display: true,
            text: 'Интенсивность',
            font: {
              size: 14,
              weight: 600,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
        title: {
          display: true,
          align: 'start',
          text: (ctx) => {
            return 'Частота испытываемых эмоций ' + periodString.value.toLowerCase();
          },
          font: {
            size: 20,
            weight: 400,
          },
          padding: {
            top: 10,
            bottom: 30,
          },
        },
      },
      datasets: {
        bar: {
          categoryPercentage: 0.95,
          barPercentage: 0.95,
        },
      }
    };

    const triggerBarOptions = {
      scales: {
        x: {
          grid: {
            display: false
          },
          title: {
            display: true,
            text: 'Причина',
            font: {
              size: 14,
              weight: 600,
            },
          },
        },
        y: {
          min: 0,
          max: (ctx) => {
            if (analyticsData.value) {
              return analyticsData.value.triggers[0].count + 1;
            }
          },
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
          title: {
            display: true,
            text: 'Частота причины',
            font: {
              size: 14,
              weight: 600,
            },
          },
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false
        },
        title: {
          display: true,
          align: 'start',
          text: (ctx) => {
            return 'Причины возникновения эмоций ' + periodString.value.toLowerCase()
          },
          font: {
            size: 20,
            weight: 400,
          },
          padding: {
            top: 10,
            bottom: 30
          }
        }
      }
    }

    const periods = [
      {
        id: 'week',
        text: 'За последнюю неделю'
      },
      {
        id: 'month',
        text: 'За месяц'
      },
      {
        id: 'quarter',
        text: 'За квартал'
      },
      {
        id: 'year',
        text: 'За год'
      },
      {
        id: 'custom',
        text: 'Другой период'
      }
    ];

    const getAnalyticsData = () => {
      let dates = {};

      if (periodForm.value === 'custom') {
        if (!customPeriodForm.start_date || !customPeriodForm.end_date) {
          notify({
            text: 'Введите даты начала и конца периода',
            type: 'warning',
          });
          return;
        }

        dates = {
          start_date: customPeriodForm.start_date,
          end_date: customPeriodForm.end_date,
        };

        periodString.value = '';
      } else {
        let period = new Date();

        if (periodForm.value === 'week') {
          period.setDate(period.getDate() - 7)
        }

        if (periodForm.value === 'month') {
          period.setMonth(period.getMonth() - 1)
        }

        if (periodForm.value === 'quarter') {
          period.setMonth(period.getMonth() - 3)
        }

        if (periodForm.value === 'year') {
          period.setFullYear(period.getFullYear() - 1)
        }

        let selectedDate = period.getDate();
        let selectedMonth = period.getMonth() + 1;

        dates = {
          start_date: period.getFullYear() + '-' + (selectedMonth < 10 ? '0' + selectedMonth : selectedMonth) + '-' + (selectedDate < 10 ? '0' + selectedDate : selectedDate),
          end_date: todayDate,
        }

        periodString.value = periods.find((el) => el.id === periodForm.value).text;
      }

      isLoaded.value = false;
      store.dispatch('diary/getAnalytics', dates).then(() => {
        isLoaded.value = true;
      }).catch(() => isLoaded.value = true);
    }

    onBeforeMount(() => {
      getAnalyticsData();
    });

    watch(() => user.value, () => {
      if (getRight('subscription') && user.value?.company?.id) {
        store.dispatch('subscription/getPermittedSubscriptions', user.value.company?.id);
      }
    });

    return {
      periods,
      periodForm,
      todayDate,
      customPeriodForm,
      getAnalyticsData,
      isLoaded,
      analyticsData,
      emotionData,
      triggerData,
      emotionBarOptions,
      triggerBarOptions,
      getRight,
      permittedSubscriptions
    };
  }
}
</script>


<style scoped lang="scss">
.header_green {
  font-size: 24px;
  font-weight: bold;
  color: #51bb50;
}

.analytics_controls {
  margin-bottom: 50px;
}

.analytics_form {
  width: 100%;
  display: flex;
  align-items: center;
}

.select_wrapper {
  min-width: 85%
}

.custom_form {
  padding-left: 10px;
  display: flex;
}

.preloader {
  text-align: center;
  padding: 100px 50px;
}

.no_results {
  text-align: center;
  padding: 100px 0;
}

.card {
  min-height: 500px;
}

.chart_wrapper {
  display: flex;
  justify-content: space-evenly;
  column-gap: 30px;
  flex-wrap: wrap;
}

.chart {
  width: 500px;
  height: 450px;
}

.subscription-required {
  padding-bottom: 30px;
}

@media (max-width: 975px) {
  .select_wrapper {
    min-width: 100%;
  }

  .analytics_form {
    flex-wrap: wrap;
    row-gap: 15px;
  }

  .button_wrapper {
    margin-left: auto;
  }

  .custom_form {
    flex-direction: column;
  }
}
</style>